import cs from 'classnames';
import React, { ReactNode } from 'react';

export type Props = {
  className?: string;
  id: string;
  children?: ReactNode;
};

const FormDescription = ({ className, id, children }: Props) => {
  return (
    <div
      className={cs(className, 'block text-sm text-muted-foreground')}
      id={id}
    >
      {children}
    </div>
  );
};

export default FormDescription;
