import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Loading } from '@packfleet/ui';
import { Formik } from 'formik';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { IoCheckmarkCircle } from 'react-icons/io5';
import Container from '../components/container/Container';
import FormElement from '../components/form/FormElement';
import FormError from '../components/form/FormError';
import Heading from '../components/heading/Heading';
import Link from '../components/link/Link';
import Page from '../components/page/Page';
import { login } from '../utilities/request/auth';
import { requireUnauthenticated } from '../utilities/require-authentication';
import { Routes, route } from '../utilities/routes';

function Login() {
  const router = useRouter();
  const [errorMessage, setErrorMessage] = useState('');
  const redirect = router.query.redirect_uri as string;
  let redirectUrl: URL | null = null;
  if (redirect) {
    // Try to construct a URL out of the redirect URL; if it doesn't work,
    // it's likely a relative URL so we need the base URL.
    try {
      redirectUrl = new URL(redirect);
    } catch (_e) {
      // If this also throws, the redirect URL supplied is wrong. This is likely a bug so
      // we want an error to be thrown.
      redirectUrl = new URL(redirect, window.location.origin);
    }
  }

  return (
    <Page title="Log in | Packfleet" fullHeight hideNavbar>
      <Container variant="no-sidebar">
        <div className="mx-auto max-w-xl">
          {router.query.email_verified ? (
            <div className="w-100 flex justify-center">
              <div className="w-100 flex flex-1 items-center rounded-md pb-4 text-success md:justify-center">
                <IoCheckmarkCircle className="mr-2 text-success" /> Email
                verified, please log in.
              </div>
            </div>
          ) : null}
          <Formik
            initialValues={{ email: '', password: '' }}
            validateOnBlur={false}
            validate={(values) => {
              const errors: {
                email?: string;
                password?: string;
              } = {};
              if (!values.email) {
                errors.email = 'Required';
              }
              if (!values.password) {
                errors.password = 'Required';
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              try {
                const result = await login(values.email, values.password);
                if (result.user.emailVerified) {
                  if (
                    redirectUrl != null &&
                    redirectUrl.hostname === window.location.hostname
                  ) {
                    window.location.href = redirect;
                  } else {
                    window.location.href = route(Routes.home);
                  }
                } else {
                  await router.push(
                    route(Routes.verifyEmail, {
                      email: result.user.email ?? '',
                    }),
                  );
                }
              } catch (error) {
                if (error.code === 'auth/wrong-password') {
                  // TODO: Extract error enum
                  setErrorMessage('Please check your email or password.');
                  return;
                }
                if (error.code === 'auth/user-not-found') {
                  // TODO: Extract error enum
                  setErrorMessage('Please check your email or password.');
                  return;
                }
                throw error;
              }
            }}
          >
            {({
              errors,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
              isSubmitting,
            }) => {
              return (
                <form
                  onSubmit={handleSubmit}
                  className="mt:4 mx-auto max-w-md md:mt-6"
                >
                  <div className="mb-6">
                    <Heading level={1} headingStyle="heading1">
                      Log in
                    </Heading>
                    <p>
                      or{' '}
                      <Link href="https://packfleet.com/tour">
                        create an account
                      </Link>
                    </p>
                  </div>
                  <FormElement
                    label="Email"
                    error={touched.email ? errors.email : ''}
                  >
                    <Input
                      className="w-full"
                      type="email"
                      autoFocus
                      id="email"
                      name="email"
                      tabIndex={1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormElement>
                  <FormElement
                    className="mt-8"
                    label="Password"
                    error={touched.password ? errors.password : ''}
                    hint={
                      <p className="text-sm" tabIndex={4}>
                        <Link
                          href={route(Routes.resetPassword)}
                          className="block pb-2"
                        >
                          Forgot password?
                        </Link>
                      </p>
                    }
                  >
                    <Input
                      className="ph-no-capture w-full"
                      type="password"
                      id="password"
                      name="password"
                      tabIndex={2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder=""
                    />
                  </FormElement>
                  {errorMessage ? (
                    <FormError size="regular" id="formError" className="mt-4">
                      {errorMessage}
                    </FormError>
                  ) : null}
                  <div className="mt-10">
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      className="w-full"
                      tabIndex={3}
                      variant="default"
                    >
                      {isSubmitting && (
                        <Loading
                          when={isSubmitting}
                          className="mr-2"
                          color="light"
                        />
                      )}
                      {isSubmitting ? 'Logging in...' : 'Log in'}
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
          <div className="pb-6" />
        </div>
        <div className="mx-auto mt-6 max-w-xl">
          <p className="mb-8 px-6 text-center text-sm text-muted-foreground">
            Don&apos;t have an account?{' '}
            <Link href="https://packfleet.com/tour">Sign up in 2 minutes</Link>{' '}
            to start shipping immediately in London with 100% carbon-neutral
            delivery.
          </p>
        </div>
      </Container>
    </Page>
  );
}
export default requireUnauthenticated(Login);
